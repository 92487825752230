import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { defaultPagingParams } from '@/common/define';
import { DateSummarize, LuotXeSummarize, LuotXeTheoNgay, LuotXeTheoNgayResponse, UpdateLuotXeTheoNgay } from '@/services/LuotXe.service';
import { RequestOptions } from '@/services/types';

interface LuotXeState {
  LuotXeData?: LuotXeSummarize | null;
  luotXeByDate?: LuotXeSummarize | null;
  loading: boolean;
  luotXeTheoNgayData?: LuotXeTheoNgayResponse | null;
  selectedLuotXeDay: LuotXeTheoNgay | null;
  MaHangData?: DateSummarize | null;
  pagination: {
    page: number;
    pageSize: number;
    total: number;
  } | null;
  queryParams: any;
}

const initialState: LuotXeState = {
  LuotXeData: null,
  luotXeByDate: null,
  loading: false,
  luotXeTheoNgayData: null,
  selectedLuotXeDay: null,
  MaHangData: null,
  pagination: null,
  queryParams: defaultPagingParams,
};

const LuotXeSlice = createSlice({
  name: 'LuotXe',
  initialState,
  reducers: {
    getLuotXe: (state, action: PayloadAction<{ projectId: number; options: RequestOptions }>) => {
      state.loading = true;
    },
    setLuotXe: (state, action: PayloadAction<LuotXeSummarize | undefined>) => {
      state.loading = false;
      state.LuotXeData = action.payload;
    },
    getLuotXeDay: (state, action: PayloadAction<{ projectId: number; day: string; options: RequestOptions }>) => {
      state.loading = true;
    },
    getLuotXeByDate: (state, action: PayloadAction<{ projectId: number; options: RequestOptions }>) => {
      state.loading = true;
    },
    setLuotXeByDate: (state, action: PayloadAction<LuotXeSummarize | undefined>) => {
      state.loading = false;
      state.luotXeByDate = action.payload;
    },
    setLuotXeTheoNgay: (state, action: PayloadAction<LuotXeTheoNgayResponse | undefined>) => {
      state.luotXeTheoNgayData = action.payload;
    },
    getLuotXeMaHang: (state, action: PayloadAction<{ projectId: number; date: string; options: RequestOptions }>) => {},
    setLuotXeMaHang: (state, action: PayloadAction<DateSummarize | undefined>) => {
      state.MaHangData = action.payload;
    },
    updateLuotXe: (state, action: PayloadAction<{projectId: number, day: string, data: UpdateLuotXeTheoNgay, options: RequestOptions}>) => {
    }
  },
});

export const LuotXeActions = LuotXeSlice.actions;
export const LuotXeReducer = LuotXeSlice.reducer;
