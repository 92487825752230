import React, { useEffect, useState } from 'react';

import { DatePicker, Row, Col, Card, Select } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, LabelList } from 'recharts';

import { CardItemDashboard } from './CardItemDashboard';
import styles from './Dashboard.module.less';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getLuotXesView, getLuotXeByDate } from '@/store/luotxe/luotxeSelectors';
import { getLuotXeMaHang } from '@/store/luotxe/luotxeSelectors';
import { LuotXeActions } from '@/store/luotxe/luotxeSlice';
import { getSelectedProject } from '@/store/project';

export const Dashboard: React.FC = () => {
  const { t } = useTranslation('portone');
  const dispatch = useAppDispatch();
  const selectedProject = useAppSelector(getSelectedProject());

  const luotXeMaHang = useAppSelector(getLuotXeMaHang());
  const luotxeSummarize = useAppSelector(getLuotXesView());
  const luotxeSummarizeBDate = useAppSelector(getLuotXeByDate());

  const [data, setData] = useState(luotxeSummarize);
  const [date, setDate] = useState(dayjs());

  const [projectId, setProjectId] = useState<string>('1');

  useEffect(() => {
    const updateProjectId = () => {
      if (selectedProject !== null) {
        //check nếu selected không null thì thực hiện
        if (selectedProject.id === 1) {
          setProjectId('1');
        } else if (selectedProject.id === 2) {
          setProjectId('2');
        }
      }
    };

    updateProjectId();
  }, [selectedProject]);

  const [luotXeChart, setluotXeChart] = useState(
    luotxeSummarizeBDate?.dateSummarizes.map(item => ({
      name: item.date.split('-')[item.date.split('-').length - 1],
      luotxe: item.tong.luotXe,
      luotXeAccess: item.tong.luotXeAccess,
      can: (item.tong.soCan / 1000).toFixed(1),
      canAccess: (item.tong.soCanAccess / 1000).toFixed(1),
    })),
  );

  useEffect(() => {
    console.log(luotxeSummarizeBDate);
    setluotXeChart(
      luotxeSummarizeBDate?.dateSummarizes.map(item => ({
        name: item.date.split('-')[item.date.split('-').length - 1],
        luotxe: item.tong.luotXe,
        luotXeAccess: item.tong.luotXeAccess,
        can: (item.tong.soCan / 1000).toFixed(1),
        canAccess: (item.tong.soCanAccess / 1000).toFixed(1),
      })),
    );
  }, [luotxeSummarizeBDate]);

  const [dataMaHang, setDataMaHang] = useState(
    luotXeMaHang?.dateSummarizes.map(item => ({
      luotxeMaHang: item.luotXe,
      canMaHang: item.soCan / 1000,
    })),
  );

  useEffect(() => {
    console.log('mã hàng', luotXeMaHang);

    setDataMaHang(
      luotXeMaHang?.dateSummarizes.map(item => ({
        luotxeMaHang: item.luotXe,
        canMaHang: item.soCan / 1000,
      })),
    );
  }, [luotXeMaHang]);

  const [dateLuotXe, setDateLuotXe] = useState({
    startDate: dayjs(),
    endDate: dayjs(),
  });

  useEffect(() => {
    setData(luotxeSummarize);
  }, [luotxeSummarize]);

  useEffect(() => {
    if (projectId === '1') {
      getMaHang();
    }
    getDashboardData();
    getLuotXeSummaizeByDate();
  }, []);

  useEffect(() => {
    getDashboardData();
    if (projectId === '1') {
      getMaHang();
    }
  }, [projectId, date]);

  useEffect(() => {
    getLuotXeSummaizeByDate();
  }, [projectId, dateLuotXe]);

  const getDashboardData = () => {
    console.log('Giá trị projectId getDashboardData:', projectId);

    if (date) {
      dispatch(
        LuotXeActions.getLuotXe({
          projectId: Number(projectId),
          options: {
            search: {
              startDate: date.format('YYYY-MM-DD'),
              endDate: date.format('YYYY-MM-DD'),
            },
          },
        }),
      );
    }
  };

  const getLuotXeSummaizeByDate = () => {
    console.log('Giá trị projectId getLuotXeSummaizeByDate:', projectId);

    if (date) {
      dispatch(
        LuotXeActions.getLuotXeByDate({
          projectId: Number(projectId),
          options: {
            search: {
              startDate: dateLuotXe.startDate.format('YYYY-MM-DD'),
              endDate: dateLuotXe.endDate.format('YYYY-MM-DD'),
            },
          },
        }),
      );
    }
  };

  const getMaHang = () => {
    console.log('projectId mã hàng đang được chọn là :', projectId);
    if (date) {
      dispatch(
        LuotXeActions.getLuotXeMaHang({
          projectId: Number(projectId),
          date: date ? date.format('YYYY-MM-DD') : '',
          options: {},
        }),
      );
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <h2 style={{ fontSize: '24px', margin: 0, marginLeft: 10, marginBottom: 10 }}>{t('Dashboard')}</h2>
        </div>
        <div className={styles.tableheader}>
          <DatePicker
            defaultValue={date}
            style={{ width: '200px', minWidth: '120px', marginLeft: '10px' }}
            onChange={x => x && setDate(x)}
          />

          {/* <Select defaultValue="optionA" style={{ width: '200px', minWidth: '120px', marginLeft: '10px' }}>
            <Option value="optionA">Option A</Option>
            <Option value="optionB">Option B</Option>
            <Option value="optionC">Option C</Option>
          </Select> */}
        </div>
      </div>
      <div className={styles.table}>
        <div className={styles.tablelast}>
          <Row gutter={[16, 16]} justify="space-between">
            {selectedProject && selectedProject.id === 1 ? (
              <>
                <Col xs={24} sm={12} lg={6}>
                  <CardItemDashboard
                    title={'Tổng lượt xe'}
                    value={data?.tong.luotXe ? Intl.NumberFormat().format(data.tong.luotXe) : '0'}
                  />
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <CardItemDashboard
                    title={'Tổng lượt ra'}
                    value={data?.phieuRa ? Intl.NumberFormat().format(data.phieuRa) : '0'}
                  />
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <CardItemDashboard
                    title={'Tổng cân'}
                    value={`${data?.tong.soCan ? Intl.NumberFormat().format(data.tong.soCan) : '0'} KG`}
                  />
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <CardItemDashboard
                    title={'Tổng lượt vào'}
                    value={data?.phieuVao ? Intl.NumberFormat().format(data.phieuVao) : '0'}
                  />
                </Col>
              </>
            ) : (
              <>
                <Col xs={24} sm={8}>
                  <CardItemDashboard
                    title={'Tổng lượt xe'}
                    value={data?.tong.luotXe ? Intl.NumberFormat().format(data.tong.luotXe) : '0'}
                  />
                </Col>
                <Col xs={24} sm={8}>
                  <CardItemDashboard
                    title={'Tổng lượt ra cổng 1'}
                    value={data?.gateSummarize && data?.gateSummarize[0] ? data?.gateSummarize[0].phieuRa : '0'}
                    numberError={data?.gateSummarize && data?.gateSummarize[0] ? data?.gateSummarize[0].errorRa : 0}
                    errorLink='/project/vehicle/portoneday?isError=true'
                  />
                </Col>
                <Col xs={24} sm={8}>
                  <CardItemDashboard
                    title={'Tổng lượt ra cổng 2'}
                    value={data?.gateSummarize && data?.gateSummarize[1] ? data?.gateSummarize[1].phieuRa : '0'}
                    numberError={data?.gateSummarize && data?.gateSummarize[1] ? data?.gateSummarize[1].errorRa : 0}
                    errorLink='/project/vehicle/porttwoday?isError=true'
                  />
                </Col>
              </>
            )}

            {selectedProject && selectedProject.id === 1 ? (
              <>
                <Col xs={12}>
                  <div className={styles.chartContainer}>
                    <Card>
                      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                        <h1>{'Biểu đồ lượt xe theo ngày'}</h1>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                          <DatePicker
                            defaultValue={dateLuotXe.startDate}
                            style={{ width: '200px', minWidth: '120px', marginLeft: '10px' }}
                            onChange={x => x && setDateLuotXe({ ...dateLuotXe, startDate: x })}
                          />
                          <DatePicker
                            defaultValue={dateLuotXe.endDate}
                            style={{ width: '200px', minWidth: '120px', marginLeft: '10px' }}
                            onChange={x => x && setDateLuotXe({ ...dateLuotXe, endDate: x })}
                          />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '5px' }}>
                          <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '5px' }}>
                            <div
                              style={{
                                width: '72px',
                                height: '20px',
                                backgroundColor: 'rgba(101, 245, 157, 1)',
                                marginRight: '20px',
                              }}
                            ></div>
                            <div>Tổng số lượt xe</div>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '5px' }}>
                            <div
                              style={{
                                width: '72px',
                                height: '20px',
                                backgroundColor: 'rgba(226, 101, 245, 1)',
                                marginRight: '20px',
                              }}
                            ></div>
                            <div>Tổng số lượt xe của phần mềm cân</div>
                          </div>
                        </div>
                      </div>

                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <ResponsiveContainer width="100%" height={307}>
                          <BarChart data={luotXeChart} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                            <XAxis dataKey="name" interval={0} />
                            <YAxis 
                            domain={[0, 'dataMax + 200']} 
                            tickFormatter={value => `${value}`} />
                            <Tooltip />

                            <Bar dataKey="luotxe" fill="rgba(101, 245, 157, 1)">
                              <LabelList
                                dataKey="luotxe"
                                position="top"
                                angle={-35}
                                offset={10}
                                formatter={(value: number | string) => `${value}`}
                                style={{ fontSize: '11px', fontWeight: 'bold' }}
                              />
                            </Bar>
                            <Bar dataKey="luotXeAccess" fill="rgba(226, 101, 245, 1)">
                              <LabelList
                                dataKey="luotXeAccess"
                                position="top"
                                angle={-35}
                                offset={10}
                                formatter={(value: number | string) => `${value}`}
                                style={{ fontSize: '11px', fontWeight: 'bold' }}
                              />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                        <h1>{'Biểu đồ số cân theo ngày'}</h1>

                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '5px' }}>
                          <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '5px' }}>
                            <div
                              style={{
                                width: '72px',
                                height: '20px',
                                backgroundColor: 'rgba(226, 101, 245, 1)',
                                marginRight: '20px',
                              }}
                            ></div>
                            <div>Tổng số cân</div>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '5px' }}>
                            <div
                              style={{
                                width: '72px',
                                height: '20px',
                                backgroundColor: 'rgba(101, 245, 157, 1)',
                                marginRight: '20px',
                              }}
                            ></div>
                            <div>Tổng số cân của phần mềm cân</div>
                          </div>
                        </div>
                      </div>

                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <ResponsiveContainer width="100%" height={307}>
                          <BarChart data={luotXeChart} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                            <XAxis dataKey="name" interval={0} />
                            <YAxis
                              // domain={[0, (dataMax: number) => Math.max(100, dataMax + 10000)]}
                              tickFormatter={value => `${value}T`}
                            />
                            <Tooltip />
                            <Tooltip />
                            <Bar dataKey="can" fill="rgba(226, 101, 245, 1)">
                              <LabelList
                                dataKey="can"
                                position="top"
                                angle={-35}
                                offset={10}
                                formatter={(value: number | string) => `${value}T`}
                                style={{ fontSize: '11px', fontWeight: 'bold' }}
                              />
                            </Bar>
                            <Bar dataKey="canAccess" fill="rgba(101, 245, 157, 1)">
                              <LabelList
                                dataKey="canAccess"
                                position="top"
                                angle={-35}
                                offset={10}
                                formatter={(value: number | string) => `${value}T`}
                                style={{ fontSize: '11px', fontWeight: 'bold' }}
                              />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </Card>
                  </div>
                </Col>

                <Col xs={12}>
                  <div className={styles.chartContainer}>
                    <Card>
                      <h1>Biểu đồ thể hiện trọng lượng theo loại hàng tại cổng 1</h1>
                      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                          <div
                            style={{
                              width: '72px',
                              height: '20px',
                              backgroundColor: 'rgba(101, 245, 157, 1)',
                              marginRight: '20px',
                            }}
                          ></div>
                          <div>Tổng trọng lượng theo loại hàng của phần mềm Ting Mỏ</div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div
                            style={{
                              width: '72px',
                              height: '20px',
                              backgroundColor: 'rgba(226, 101, 245, 1)',
                              marginRight: '20px',
                            }}
                          ></div>
                          <div>Tổng trọng lượng theo loại hàng của phần mềm cân</div>
                        </div>
                      </div>

                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <ResponsiveContainer width="100%" height={307}>
                          <BarChart
                            data={
                              dataMaHang && dataMaHang.length > 0 ? dataMaHang : [{ luotxeMaHang: 0, canMaHang: 0 }]
                            }
                          >
                            <XAxis dataKey="name" interval={0} />
                            <YAxis domain={[0, 'dataMax + 100']} tickFormatter={value => `${value}T`} />
                            <Tooltip />
                            <Bar dataKey="luotxeMaHang" fill="rgba(101, 245, 157, 1)">
                              <LabelList
                                dataKey="luotxeMaHang"
                                position="top"
                                angle={-35}
                                offset={10}
                                formatter={(value: number | string) => `${value} T`}
                                style={{ fontSize: '11px', fontWeight: 'bold' }}
                              />
                            </Bar>
                            <Bar dataKey="canMaHang" fill="rgba(226, 101, 245, 1)">
                              <LabelList
                                dataKey="canMaHang"
                                position="top"
                                angle={-35}
                                offset={10}
                                formatter={(value: number | string) => `${value} T`}
                                style={{ fontSize: '11px', fontWeight: 'bold' }}
                              />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </Card>
                  </div>
                </Col>
              </>
            ) : (
              <>
                <Col xs={12}>
                  <Card>
                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                      <h1>{'Biểu đồ lượt xe theo ngày'}</h1>
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                        <DatePicker
                          defaultValue={dateLuotXe.startDate}
                          style={{ width: '200px', minWidth: '120px', marginLeft: '10px' }}
                          onChange={x => x && setDateLuotXe({ ...dateLuotXe, startDate: x })}
                        />
                        <DatePicker
                          defaultValue={dateLuotXe.endDate}
                          style={{ width: '200px', minWidth: '120px', marginLeft: '10px' }}
                          onChange={x => x && setDateLuotXe({ ...dateLuotXe, endDate: x })}
                        />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '5px' }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '5px' }}>
                          <div
                            style={{
                              width: '72px',
                              height: '20px',
                              backgroundColor: 'rgba(101, 245, 157, 1)',
                              marginRight: '20px',
                            }}
                          ></div>
                          <div>Tổng số lượt xe</div>
                        </div>
                      </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <ResponsiveContainer width="100%" height={307}>
                        <BarChart data={luotXeChart} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                          <XAxis dataKey="name" interval={0} />
                          <YAxis domain={[0, 'dataMax + 100']} tickFormatter={value => `${value}`} />
                          <Tooltip />

                          <Bar dataKey="luotxe" fill="rgba(101, 245, 157, 1)">
                            <LabelList
                              dataKey="luotxe"
                              position="top"
                              angle={-35}
                              offset={10}
                              formatter={(value: number | string) => `${value}`}
                              style={{ fontSize: '11px', fontWeight: 'bold' }}
                            />
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </Card>
                </Col>
              </>
            )}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
