import { Observable, tap } from 'rxjs';

import HttpClient from './HttpClient';
import { RequestOptions } from './types';
import { getEnvVars } from '@/environment';

const { apiUrl } = getEnvVars();

export interface DataPortTwo {
  trigger_cam_id: number;
  analysis_cam_id: number;
  trigger_code: string;
  section_id: string;
  analysis_code: string;
  detected_object_id: string;
  trigger_image: string;
  trigger_extra_data: string;
  analysis_image: string;
  analysis_extra_data: string;
  analysis_output: string;
  timestamp: string;
}

export interface PortTwoResponse {
  results: DataPortTwo[];
  page: number;
  pageSize: number;
  queryCount: number;
}

class PortTwoController {
  public Get = {
    getAllDataPortTwo: (
      projectId: number,
      gateNo: number,
      workingDay: string,
      options?: RequestOptions,
    ): Observable<DataPortTwo[]> => {
      console.log('Request Options:', options);

      return HttpClient.get(`${apiUrl}/api/KafkaMsg/vehicle/${projectId}/${gateNo}/${workingDay}`, options).pipe(
        tap(response => {
          console.log('Response Data:', response);
        }),
      );
    },
  };
}
export const PortTwoService = new PortTwoController();
