import { Observable } from 'rxjs';

import HttpClient from './HttpClient';
import { RequestOptions } from './types';
import { getEnvVars } from '@/environment';

const { apiUrl } = getEnvVars();
export interface LuotXeSummarize {
  dateSummarizes: {
    date: string;
    tong: {
      luotXeAccess: number;
      luotXe: number;
      soCan: number;
      soCanAccess: number;
    };
    phieuRa: number;
    phieuVao: number;
  }[];
  tong: {
    luotXeAccess: number;
    luotXe: number;
    soCan: number;
    soCanAccess: number;
  };
  phieuRa: number;
  phieuVao: number;
  gateSummarize:  
    {
      gate: string;
      phieuRa: number
      phieuVao: number,
      errorVao: number,
      errorRa: number
    }[]
  
}
export interface DateSummarize {
  dateSummarizes: {
    maHang: string | null; // Thuộc tính maHang
    luotXe: number; // Số lượt xe
    soCan: number; // Số cân
  }[];
  tong: {
    luotXe: number; // Tổng số lượt xe
    soCan: number; // Tổng số cân
  };
  phieuRa: number; //  phiếu ra
  phieuVao: number; //  phiếu vào
}

export interface LuotXeTheoNgayResponse {
  results: LuotXeTheoNgay[];
  page: number;
  pageCount: number;
  pageSize: number;
  queryCount: number;
  firstRowIndex: number;
  lastRowIndex: number;
}
export interface LuotXeTheoNgay {
  date_Key: number;
  detectedObjectId: string;
  triggerImage: string;
  type: number;
  maHang: string;
  weight: number;
  thungXeImage: string;
  weightIn: number;
  weightOut: number;
  timestampOut: string;
  timestampIn: string;
  loaiXe: string;
  id: number;
  status: string | null | undefined;
  key: number;
}
export interface UpdateLuotXeTheoNgay {
  maHang?: string;
  weight?: number;
  weightIn?: number;
  weightOut?: number;
  loaiXe?: string;
  status?: string | null | undefined;
  id: number;
}
class LuotXeController {
  public Get = {
    getReportByDate: (projectId: number, options?: RequestOptions): Observable<LuotXeSummarize> => {
      return HttpClient.get(`${apiUrl}/LuotXe/report/${projectId}`, options);
    },
    getLuotXeTheoNgay: (
      projectId: number,
      day: string,
      options?: RequestOptions,
    ): Observable<LuotXeTheoNgayResponse> => {
      return HttpClient.get(`${apiUrl}/LuotXe/project/${projectId}/date/${day}`, options);
    },
    getLuotXeMaHang: (projectId: number, date: string, options?: RequestOptions): Observable<DateSummarize> => {
      return HttpClient.get(`${apiUrl}/LuotXe/report/${projectId}/mahang?date=${date}`, options);
    },
  };
  public Put = {
    updateLuotXeTheoNgay: (
      Id: number,
      data: UpdateLuotXeTheoNgay,
    ): Observable<LuotXeTheoNgay> => {
      return HttpClient.put(`${apiUrl}/LuotXe/${Id}/status`, data);
    },
  }
}
export const LuotXeService = new LuotXeController();
