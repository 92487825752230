import { filter, withLatestFrom, switchMap, concat, mergeMap, catchError, of } from 'rxjs';

import { LuotXeActions } from './luotxeSlice';
import { startLoading, stopLoading } from '../loading';
import { RootEpic } from '../types';
import { GetLuotXeByDate, GettingLuotXeList } from '@/common/define';
import { LuotXeService } from '@/services/LuotXe.service';
import Utils from '@/utils';

const getLuotXesRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(LuotXeActions.getLuotXe.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, options } = action.payload;
      return concat(
        [startLoading({ key: GettingLuotXeList })],
        LuotXeService.Get.getReportByDate(projectId, options).pipe(
          mergeMap(LuotXes => {
            return [LuotXeActions.setLuotXe(LuotXes)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [LuotXeActions.setLuotXe(undefined)];
          }),
        ),
        [stopLoading({ key: GettingLuotXeList })],
      );
    }),
  );
};

const getLuotXeByDateRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(LuotXeActions.getLuotXeByDate.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, options } = action.payload;
      return concat(
        [startLoading({ key: GetLuotXeByDate })],
        LuotXeService.Get.getReportByDate(projectId, options).pipe(
          mergeMap(LuotXes => {
            return [LuotXeActions.setLuotXeByDate(LuotXes)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [LuotXeActions.setLuotXeByDate(undefined)];
          }),
        ),
        [stopLoading({ key: GetLuotXeByDate })],
      );
    }),
  );
};
const updateLuotXeRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(LuotXeActions.updateLuotXe.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      let {projectId, data, day, options} = action.payload;
      console.log(data)
      return concat(
        [startLoading({ key: GetLuotXeByDate })],
        LuotXeService.Put.updateLuotXeTheoNgay(data.id, data).pipe(
          mergeMap(LuotXes => {
            return [LuotXeActions.getLuotXeDay({projectId, day, options})];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: GetLuotXeByDate })],
      );
    }),
  );
};

const getLuotXeTheoNgay$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(LuotXeActions.getLuotXeDay.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, day, options } = action.payload;

      return concat(
        LuotXeService.Get.getLuotXeTheoNgay(projectId, day, options).pipe(
          mergeMap(luotXeTheoNgay => {
            return of(LuotXeActions.setLuotXeTheoNgay(luotXeTheoNgay));
          }),
          catchError(error => {
            Utils.errorHandling(error); // lỗi
            return of(LuotXeActions.setLuotXeTheoNgay(undefined));
          }),
        ),
      );
    }),
  );
};

const getLuotXeMaHang$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(LuotXeActions.getLuotXeMaHang.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, date, options } = action.payload;
      return concat(
        [startLoading({ key: 'luot xe theo ma hang' })],
        LuotXeService.Get.getLuotXeMaHang(projectId, date, options).pipe(
          mergeMap(LuotXe => {
            return [LuotXeActions.setLuotXeMaHang(LuotXe)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [LuotXeActions.setLuotXeMaHang(undefined)];
          }),
        ),
        [stopLoading({ key: 'luot xe theo ma hang' })],
      );
    }),
  );
};

export const LuotXeEpics = [getLuotXeTheoNgay$, getLuotXesRequest$, getLuotXeByDateRequest$, getLuotXeMaHang$, updateLuotXeRequest$];
