import React from 'react';

import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { Form, Input, Button, Row, Col, Checkbox, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import './LoginPage.css';
import { appActions } from '@/store/app';

export const LoginPage: React.FC = () => {
  const { t } = useTranslation('login');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFinish = (values: { username: string; password: string }) => {
    const { username, password } = values;

    const validUsers = [
      { username: 'admin', password: '123456' },
      { username: 'modat', password: 'modat' },
      { username: 'moda', password: 'moda' },
    ];

    const user = validUsers.find(user => user.username === username && user.password === password);

    if (user) {
      const auth = {
        user: {
          Firstname: user.username,
          Lastname: user.username,
        },
        token: '',
        refresh_token: '',
        remember: true,
        roles: '',
        orgRoles: '',
      };
      dispatch(appActions.setLoginData(auth));
      navigate('/');
    } else {
      message.error(t('Invalid username or password'));
    }
  };

  return (
    <div className="container">
      <div className="image-form">
        <div className="form-section">
          <Form name="login_form" layout="vertical" style={{ maxWidth: '100%', margin: 'auto' }} onFinish={onFinish}>
            <div className="textheader">{t('Login')}</div>

            <Form.Item
              name="username"
              label={t('Username')}
              rules={[{ required: true, message: t('Please enter your username') }]}
            >
              <Input prefix={<MailOutlined />} placeholder={t('enter your account')} className="custom-input" />
            </Form.Item>

            <Form.Item
              name="password"
              label={t('Password')}
              rules={[{ required: true, message: t('Please enter your password') }]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder={t('enter your password')}
                className="custom-input"
              />
            </Form.Item>

            <Row gutter={16} justify="space-between">
              <Col>
                <Form.Item>
                  <Checkbox checked style={{ fontSize: '16px', marginBottom: '30px' }}>
                    {t('Remember')}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t('Login')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
