import { Card } from 'antd';

import styles from './Dashboard.module.less';
interface CardItemDashboardProps {
  title: string;
  value?: number | string;
  percentage?: number;
  numberError?: number;
  errorLink?: string;
  icon?: React.ReactNode;
  percentageColor?: string;
  percentageText?: string;
}
export const CardItemDashboard = (props: CardItemDashboardProps) => {
  return (
    <Card className={styles.detailInfo} title={props.title}>
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <p style={{ fontWeight: 1000, fontSize: '30px', marginBottom: '1px' }}>{props.value ?? 0}</p>
        {props.numberError ? (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <a style={{ fontSize: '16px', color: '#ff0000', marginRight: 5 }} href={props.errorLink}>{props.numberError ?? 0}</a>
              <p style={{ fontSize: '14px' }}> {' lượt xe cần kiểm tra biển số'}</p>
            </div>
          </>
        ) : null}
        {props.percentage ? (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {props.icon}
              <p style={{ fontSize: '16px', color: props.percentageColor ?? '#52c41a' }}>{props.percentage ?? 0}</p>
            </div>
            <p style={{ fontSize: '14px' }}>{props.percentageText}</p>
          </>
        ) : null}
      </div>
    </Card>
  );
};
